
import { defineComponent, ref, toRef } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Account } from "@/store/modules/AuthModule";
import { useStore } from "vuex";

export default defineComponent({
  name: "edit-account-branding-modal",
  components: {},
  props: ["account", "location"],

  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const editBrandingModalRef = ref<null | HTMLElement>(null);
    const loading = ref<number>(0);
    const store = useStore();
    const file = ref<File | null>();

    const targetData = toRef(props, "account");

    const rules = ref({});

    async function saveImage() {
      if (file.value) {
        const formData = new FormData();
        formData.append("form[uploadedFile]", file.value);
        loading.value++;
        ApiService.upload(
          "api/v1/accounts/" + store.state.AuthModule.account.id + "/images",
          formData
        )
          .then((response) => {
            store.commit(Mutations.SET_ACCOUNT_IMAGE, response.data);
          })
          .finally(() => {
            loading.value--;
          });
      }
    }

    function onFileChanged($event: Event) {
      const target = $event.target as HTMLInputElement;
      if (target && target.files) {
        file.value = target.files[0];
        saveImage();
      }
    }

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value++;

          ApiService.update("api/v1/accounts", props.account.id, {
            primaryColor: targetData.value.primaryColor,
            primaryTextColor: targetData.value.primaryTextColor,
            secondaryColor: targetData.value.secondaryColor,
            secondaryTextColor: targetData.value.secondaryTextColor,
            backgroundColor: targetData.value.backgroundColor,
            logo: targetData.value.logo,
            primaryBannerAd: targetData.value.primaryBannerAd,
            bodyTextColor: targetData.value.bodyTextColor,
          })
            .then(() => {
              store.dispatch(Actions.REFRESH_ACCOUNT);
              Swal.fire({
                text: "Your updates have been successfully saved!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editBrandingModalRef.value);
              });
            })
            .finally(() => {
              loading.value--;
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      targetData,
      submit,
      store,
      loading,
      formRef,
      onFileChanged,
      rules,
      editBrandingModalRef,
    };
  },
  methods: {
    safeUrlAccess: function (imageIri) {
      if (imageIri in store.state.AuthModule.accountImages) {
        return store.state.AuthModule.accountImages[imageIri].publicUrl;
      }
      return "";
    },
  },
});
