
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditBrandingModal from "@/views/sbmx/modals/EditBrandingModal.vue";
import store from "@/store";

export default defineComponent({
  name: "account-billing",
  components: { EditBrandingModal },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Plan", ["Account"]);
    });
  },
  methods: {
    safeUrlAccess: function (imageIri) {
      if (imageIri in store.state.AuthModule.accountImages) {
        return store.state.AuthModule.accountImages[imageIri].publicUrl;
      }
      return "";
    },
  },
});
